/* Color palette: 
    Primary: #231942 (RGB: 35, 25, 66)
    Secondary: #ffebf5 (RGB: 255, 235, 245)
    Primary Button: #5e548e (RGB: 94, 84, 142)
    Secondary Button: #efd9f2 (RGB: 239, 217, 242)
    Accent: #8d73b0 (RGB: 141, 115, 176)
    MORE: https://mycolor.space/?hex=%23231942&sub=1
*/ 

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f0f0f0;
}

body {
  padding: 0;
  margin: 0;
}
