.navbar {
  width: 100%;
  height: 75px;
  background: #231942;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 50px;
  margin-left: 20px;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
  font-size: 25px;
}

.toggleButton {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

#open {
  height: 100vh;
  flex-direction: column;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .navbar a {
    margin: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close {
    justify-content: space-between;
  }
  #close a {
    display: none;
  }
  #close .links {
    width: 0%;
  }
  .icon-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 601px) {
  .toggleButton {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row;
  }
}
